<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Şifre Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Şifre Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Şifre Güncelleme",
          active: true,
        },
      ],
      values: {
        password: "",
        newPassword: "",
      },
      alert: {
        message: "",
        variant: "",
        isAlert: false,
      }
    };
  },
  methods: {
    submit() {
      const api_url = process.env.VUE_APP_BASEURL + "/users/password";
      const data = {
        password: this.values.password,
        newPassword: this.values.newPassword,
      } 
      axios
        .put(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          this.alert.message = res.data.message
          this.alert.variant = res.data.variant
          this.alert.isAlert = true
        })
        .catch((error) => {
          this.alert.message = error.data.message
          this.alert.variant = error.data.variant
          this.alert.isAlert = true
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <b-alert v-if="alert.isAlert" show :variant=alert.variant>{{ alert.message }}</b-alert>
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group mb-3">
                    <label for="password">Eski Şifre</label>
                    <input v-model="values.password" type="password" class="form-control" id="password" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group mb-3">
                    <label for="newPassword">Yeni Şifre</label>
                    <input v-model="values.newPassword" type="password" class="form-control" id="newPassword" />
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="d-flex justify-content-end align-items-center">
                    <button type="submit" class="btn btn-primary px-4">Kaydet</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
